import React from 'react';
import './App.css';
import logo from './assets/oasis_Logo_lt4.png';
import mainImage from './assets/main.png';
import bed1 from './assets/bed1.png';
import bed2 from './assets/bed2.png';
import bed3 from './assets/bed3.png';
import mattress1 from './assets/mattress1.png';
import mattress2 from './assets/mattress2.png';
import mattress3 from './assets/mattress3.png';

function App() {
  return (
    <div className="App">
      <header>
        <img src={logo} alt="OasisFurniture Logo"/>
      </header>
      
      <div className="main-image">
        <div className="overlay-text">Welcome to Oasis Furniture Pakistan</div>
        <img src={mainImage} alt="Main Image" />
      </div>

      <section id="beds">
        <h2>Beds</h2>
        <div className="image-gallery">
          <img src={bed1} alt="Oasis Furniture Bed AI generated" />
          <img src={bed2} alt="Oasis Furniture Bed AI generated 2" />
          <img src={bed3} alt="Oasis Furniture Bed AI generated 3" />
        </div>
      </section>

      <section id="mattresses">
        <h2>Mattresses</h2>
        <div className="image-gallery">
          <img src={mattress1} alt="Oasis-Furniture Mattress 1" />
          <img src={mattress2} alt="Oasis-Furniture Mattress 2" />
          <img src={mattress3} alt="Oasis-Furniture Mattress 3" />
        </div>
      </section>

      <section id="mission">
        <h2>Our Mission</h2>
        <div className="mission-content">
          <p>
            At OasisFurniture, our mission is to provide high-quality, comfortable, and stylish furniture that enhances your living space.
            We believe in sustainability and innovation, ensuring that our products are not only beautiful but also environmentally friendly.
          </p>
        </div>
      </section>

      <footer>
        <p>&copy; 2024 OasisFurniture. All rights reserved.</p>
        <div><p><div><b>ADDRESS:</b></div>Oasis Furniture Mianwali Road, Near Hascol Pump, Talagang</p></div>
        <div><p><div><b>CONTACT US: </b></div>+92 312 5702512 <div>sales@oasisfurniture.pk</div></p></div>
      </footer>
    </div>
  );
}

export default App;
